import { PageData, PageRef } from '@wix/platform-editor-sdk';
import { FlowAPI, FlowEditorSDK, IHttpClient } from '@wix/yoshi-flow-editor';
import { Severity } from '@wix/yoshi-flow-editor/build/cjs/exports/error-monitor';
import { ControllerType, EditorAppContext } from '../platform/types';
import { assignPermissions } from '@wix/ambassador-identity-members-groups-v1-group-info/http';
import { AssignPermissionsRequest } from '@wix/ambassador-identity-members-groups-v1-group-info/types';
import editorXDefaultPage from '../assets/pages/editorXDefaultPage.json';
import classicEditorDefaultPage from '../assets/pages/classicEditorDefaultPage.json';

const PERMISSIONS_PARAMS = {
  group_id: '00000000-0000-0000-0000-000000000001',
  permissions_id: 'cce6b124-b14c-4383-aee9-5e0814811ae2',
  context: 'Pages',
};

const getPageStructure = async (
  token: string,
  isEditorX: boolean,
): Promise<any> => {
  const defaultPage = isEditorX ? editorXDefaultPage : classicEditorDefaultPage;

  return defaultPage
    ? {
        ...defaultPage,
        data: {
          hideTitle: false,
          isLandingPage: true,
          indexable: false,
          hidePage: true,
          managingAppDefId: token, // this will make it to be under dashboard pages in the pages list
          pageSecurity: {
            requireLogin: true,
          },
        },
      }
    : {
        data: {
          hideTitle: false,
          isLandingPage: true,
          indexable: false,
          hidePage: true,
          managingAppDefId: token, // this will make it to be under dashboard pages in the pages list
          pageSecurity: {
            requireLogin: true,
          },
        },
        components: [],
      };
};

export const setPagePermissions = (
  pageRef: PageRef,
  httpClient: IHttpClient,
) => {
  const assignPermissionRequest: AssignPermissionsRequest = {
    groupId: PERMISSIONS_PARAMS.group_id,
    permissions: [
      {
        id: PERMISSIONS_PARAMS.permissions_id,
        context: PERMISSIONS_PARAMS.context,
        resourceIds: [pageRef.id],
      },
    ],
  };

  return httpClient.request(assignPermissions(assignPermissionRequest));
};

export async function addControllerToPage(
  editorSDK: FlowEditorSDK,
  appDefinitionId: string,
  pageRef: PageRef,
) {
  await editorSDK.components.add(appDefinitionId, {
    componentDefinition: {
      componentType: 'platform.components.AppController',
      data: {
        controllerType: ControllerType.SingleAdminPage,
        name: 'admin-pages-controller',
        applicationId: appDefinitionId,
        settings: JSON.stringify({
          pageId: pageRef.id,
        }),
      },
    },
    pageRef,
  });
}

export function openPagesPanel(editorSDK: FlowEditorSDK, pageRef: PageRef) {
  return editorSDK.editor.openPagesPanel('token', {
    renameEnabled: true,
    pageRef,
    initialSettingsTabType: 'PAGE_INFO',
  });
}

export async function createPage({
  editorSDK,
  httpClient,
  token,
  isEditorX,
  translate,
}: {
  editorSDK: FlowEditorSDK;
  httpClient: IHttpClient;
  token: string;
  isEditorX: boolean;
  translate: FlowAPI['translations']['t'];
}) {
  await editorSDK.history.add(token, { label: 'History' });
  const pageRef = await editorSDK.pages.add(token, {
    title: 'New Page',
    definition: await getPageStructure(token, isEditorX),
    shouldAddMenuItem: false,
  });

  await setPagePermissions(pageRef, httpClient);

  await addControllerToPage(editorSDK, token, pageRef);

  await openPagesPanel(editorSDK, pageRef);
}

export async function ensureAllPagesHaveControllers(
  appContext: EditorAppContext,
  adminPages: PageData[],
): Promise<void> {
  const { editorSDK, appDefinitionId } = appContext;
  await Promise.all(
    adminPages.map(async (adminPage) => {
      const pageRef = await editorSDK.components.getById(appDefinitionId, {
        id: adminPage.id!,
      });
      const hasController = await editorSDK.controllers.isControllerExists(
        appDefinitionId,
        {
          controllerType: ControllerType.SingleAdminPage,
          pageRef,
        },
      );
      if (!hasController) {
        appContext.flowAPI.errorMonitor.captureMessage(
          'Fixing no controller for page',
          {
            level: Severity.Info,
            tags: {
              pageId: pageRef.id,
            },
          },
        );
        await addControllerToPage(editorSDK, appDefinitionId, pageRef);
      }
    }),
  );
}

// related to new admin pages template which included controllers.
// each page had 3 controllers (2 from template, and 1 that we are adding). In the server we chose the wrong one.
// related bug: https://jira.wixpress.com/browse/WOSBM-6876
export async function fixGhostControllers(
  appContext: EditorAppContext,
  adminPages: PageData[],
): Promise<void> {
  const { editorSDK, appDefinitionId } = appContext;
  // each controller data saves the page id like so {pageId: some-guid}
  // this function:
  // 1) fetches all the controllers on the page
  // 2) searches for controllers that their page id is not the current one
  // 3) removes these controllers
  await Promise.all(
    adminPages.map(async (adminPage) => {
      // get controllers on the page
      const pageRef = await editorSDK.components.getById(appDefinitionId, {
        id: adminPage.id!,
      });
      const adminPagesControllers = await editorSDK.controllers.listControllers(
        appDefinitionId,
        { pageRef },
      );
      // for all controllers, get the page id saved on the config, remove if the id is not the current page id
      await Promise.all(
        adminPagesControllers.map(async ({ controllerRef }) => {
          // page id saved on the controller data
          const pageId = (
            await editorSDK.controllers.getData(appDefinitionId, {
              controllerRef,
            })
          )?.config?.pageId;
          if (pageId !== pageRef.id) {
            appContext.flowAPI.errorMonitor.captureMessage(
              'Fixing ghost component',
              {
                level: Severity.Info,
                tags: {
                  pageId: pageRef.id,
                },
              },
            );
            await editorSDK.components.remove(appDefinitionId, {
              componentRef: controllerRef,
            });
          }
        }),
      );
    }),
  );
}

export async function removePage(editorSDK, pageRef) {
  const databindingApi = await editorSDK.document.application.getPublicAPI(
    'unused-token',
    {
      appDefinitionId: 'dataBinding',
    },
  );
  const isDynamicPage = await databindingApi.isDynamicPage({
    pageRef,
  });
  if (isDynamicPage) {
    await databindingApi.removeDynamicPage({
      pageRef,
    });
  } else {
    await editorSDK.pages.remove('unused-token', { pageRef });
  }
}
